<template>
  <VueFinalModal
    v-slot="{ close }: any"
    :click-to-close="disableClickOutside ? false : true"
    style="display: flex; justify-content: center; align-items: center"
    :focus-trap="false"
  >
    <div
      class="modal_container"
      :style="{
        height: height ?? '920px',
        'max-height': height === '100vh' ? 'none' : 'calc(100vh - 100px)',
        width: width ?? '950px',
      }"
    >
      <div v-if="$slots.help || svg" class="modal_side_panel">
        <div
          class="modal_side_info uds-shadow-s scrollbar"
          :style="{ visibility: $slots.help ? 'initial' : 'hidden' }"
        >
          <uds-icon icon-name="help" :color="primaryColors.primary500" outlined />
          <div class="help_content">
            <slot name="help" />
          </div>
        </div>
        <template v-if="svgPath">
          <component :is="svgPath" />
        </template>
      </div>

      <div class="modal_main">
        <div v-if="$slots.title" class="modal_header_wrapper">
          <span class="modal_title">
            <slot name="title" />
          </span>
          <div v-if="!hideCloseIcon" @click="close">
            <uds-icon
              class="modal_icon"
              icon-name="close"
              size="large"
              :color="udsColors.udsNeutral800"
            />
          </div>
        </div>

        <div class="modal_main_content scrollbar" style="overflow: auto">
          <div class="modal_inner scrollbar">
            <slot name="content" />
          </div>
        </div>

        <div
          v-if="$slots.footer"
          class="modal_main_footer"
          :class="{ 'centered-actions': centeredActions }"
        >
          <slot name="footer" />
        </div>
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup lang="ts">
import { VueFinalModal } from "vue-final-modal";

const props = defineProps<{
  height?: string;
  width?: string;
  svg?: string;
  disableClickOutside?: boolean;
  hideCloseIcon?: boolean;
  centeredActions?: boolean;
}>();

const svgPath = computed(() => {
  if (isDefined(props.svg)) {
    // @ts-expect-error
    return defineAsyncComponent(() => import(`../../svg/${props.svg}.vue`));
  }
  return null;
});

const udsColors = getUdsColors();
const primaryColors = getPrimaryColors();
</script>

<style lang="scss" scoped>
.modal_container {
  border-radius: $uds-radius-1;
  display: flex;
  background-color: $uds-white;

  .modal_side_panel {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    border-top-left-radius: $uds-radius-1;
    border-bottom-left-radius: $uds-radius-1;

    width: 370px;
    padding: 20px $uds-spacing-2 $uds-spacing-3 $uds-spacing-2;
    background-color: $uds-neutral-100;

    svg {
      margin-left: auto;
      margin-right: auto;
      margin-top: 10px;
    }

    .modal_side_info {
      background-color: $uds-white;
      border-radius: $uds-radius-1;
      padding: $uds-spacing-1_5 $uds-spacing-1;

      .help_content {
        line-height: 1.4;
        margin-top: $uds-spacing-1;
        text-align: center;
      }
    }
  }

  .modal_main {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px $uds-spacing-2 $uds-spacing-3 $uds-spacing-2;

    .modal_header_wrapper {
      align-items: center;
      display: flex;

      .modal_title {
        width: 100%;
        font-size: 24px;
        font-weight: $uds-weight-500;
        text-align: center;
      }
    }

    .modal_main_content {
      margin-top: $uds-spacing-2;
      height: 100%;
      max-width: calc(100vw - $uds-spacing-2 * 2);

      .modal_inner {
        height: 100%;
        max-height: 100vh;
        padding-left: $uds-spacing-3;
        padding-right: $uds-spacing-3;
      }
    }

    .modal_main_footer {
      margin-top: 15px !important;
      display: flex;
      justify-content: flex-end;
      margin-top: auto;
      gap: $uds-spacing-2;
    }
    .centered-actions {
      justify-content: center !important;
    }
  }
}
.modal_icon {
  cursor: pointer;
}
</style>
